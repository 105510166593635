<template>
  <v-app>
    <v-layout justify-center align-center class="bg-white">
      <v-layout class="box-noauth bg-main" />
      <v-layout justify-center>
        <v-layout class="w-main h-wrap-auth pos-rel py-8">
          <h2 class="logo pos-abs" />
          <v-layout justify-center align-center>
            <v-layout column class="form login-frm box-shadow px-13 py-16">
              <label
                class="text-uppercase font-weight-medium text-size-title mt-8 text-center"
              >
                {{ $t('change_password') }}
              </label>
              <p class="mt-2 mb-10 text-body-2 text-center">
                {{ $t('enter_password_confirm') }}
              </p>
              <validation-observer ref="observer" v-slot="{ invalid }">
                <v-form @submit.prevent="submit">
                  <v-layout column>
                    <label class="text-subtitle-1">
                      {{ $t('curent_password') }}
                      <span class="red--text">{{ $t('required') }}</span>
                    </label>
                    <validation-provider
                      v-slot="{ errors }"
                      name="password"
                      :rules="passwordRules"
                    >
                      <v-text-field
                        v-model="password"
                        outlined
                        dense
                        class="rounded-lg"
                        :append-icon="isPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="isPassword ? 'password' : 'text'"
                        :maxlength="maximumPassword"
                        :error-messages="errors"
                        :placeholder="$t('place_holders.password')"
                        @click:append="isPassword = !isPassword"
                      />
                    </validation-provider>
                  </v-layout>
                  <div>
                    <v-btn
                      block
                      large
                      color="primary"
                      type="submit"
                      class="rounded-lg mt-10 mb-8"
                      :disabled="invalid"
                    >
                      <span class="font-weight-medium white--text">
                        {{ $t('continue') }}
                      </span>
                    </v-btn>
                  </div>
                </v-form>
              </validation-observer>
            </v-layout>
          </v-layout>
        </v-layout>
      </v-layout>
    </v-layout>

    <!-- Dialog loading -->
    <dialog-loading v-model="showLoading" :message="$t('processing')" />

    <!-- notifications -->
    <dialog-notification
      v-model="showNoti"
      :icon-src="getIcon"
      :message="message"
    />
  </v-app>
</template>

<script>
import DialogLoading from '@/components/dialog/DialogLoading.vue'
import DialogNotification from '@/components/dialog/DialogNotification.vue'
import constants from '@/constants'
import routePaths from '@/router/routePaths'
import { FormUtils } from '@/helpers/formUtils'
import { StringUtils } from '@/helpers/stringUtils'
import { mapActions } from 'vuex'

export default {
  components: { DialogLoading, DialogNotification },
  data: () => ({
    password: '',
    isPassword: true,
    maximumPassword: constants.maximumPassword,
    passwordRules: {
      required: true,
      min: constants.minimumPassword,
      max: constants.maximumPassword,
      regex: constants.regExp.password
    },

    // loading
    showLoading: false,

    // notifications
    showNoti: false,
    typeNoti: constants.typeAlert.warning,
    message: ''
  }),
  computed: {
    getIcon() {
      return StringUtils.getIconSrc(this.typeNoti)
    }
  },
  methods: {
    ...mapActions('user', ['checkCurrentPassword']),
    async submit() {
      var valid = this.$refs.observer.validate()
      if (valid) {
        this.showLoading = true
        const pars = {
          password: this.password.trim()
        }
        const { status, data } = await this.checkCurrentPassword(pars)
        this.showLoading = false
        if (status === constants.statusCode.ok && data) {
          this.$router.push(routePaths.UPDATE_PASSWORD)
        } else {
          this.toggleDialogNoti({ state: true, msg: data.message })
          setTimeout(() => {
            this.toggleDialogNoti()
          }, constants.timeOut)
        }
      }
    },
    isNumber(evt) {
      return FormUtils.isNumber(evt)
    },
    toggleDialogNoti(
      pars = { state: false, type: constants.typeAlert.warning, msg: '' }
    ) {
      this.showNoti = pars.state
      this.typeNoti = pars.type
      this.message = pars.msg
    }
  }
}
</script>
<style src="@/styles/form.scss" lang="scss" />
